import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-theme text-white text-center p-3 mt-5">
      <p>© 2024 VSR & Associates LLP. All rights reserved.</p>
      <div>
        <a href="https://www.facebook.com" className="text-white mx-2">
          <i className="fab fa-facebook"></i>
        </a>
        <a href="https://www.linkedin.com" className="text-white mx-2">
          <i className="fab fa-linkedin"></i>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
