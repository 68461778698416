import React from 'react';
import { Helmet } from 'react-helmet';
import './Reach.css';

const Reach = () => {
  return (
    <div className="container-fluid" style={{overflow: 'hidden'}}>
      <Helmet>
        <title>VSR & Associates LLP</title>
        <meta name="description" content="Get in touch with VSR & Associates LLP" />
      </Helmet>
      <div className={window.innerWidth>500?'row justify-content-center':'row'}>
        <div className={window.innerWidth>500?'col-3 p-0':'col-12 p-0'}>
          <div className='row'>
            <div className='col-12 logo-block text-center'>
              <img src='/CA.png' style={{width: '100px', marginBottom: '16px'}} alt='' />
              <h4 className='text-theme f-bold logo-txt'>VSR & ASSOCIATES LLP</h4>
              <span className='logo-text2'>Charted Accountants</span>
            </div>
            <div className='col p-2'>
              <div className='reach-header'>
                <h1 className='text-theme f-bold'>VEMPATI SHALEM RAJU</h1>
                <p className='credentials' style={{width: '100%', textAlign: 'right'}}>ACA, ACS, CMA(USA), B.COM FAFD(ICAI)</p>
                <hr />
                <p className='m-0'>Experienced Chartered Accountant specializing in financial management, audit, and taxation, dedicated to optimizing financial performance and ensuring compliance.</p>
              </div>
            </div>
          </div>
        </div>
        <div className={window.innerWidth>500?'col-4 p-0':'col-12 p-0'}>
          <div className='row' style={{padding: '20px'}}>
            <div className='col-12 p-2'>
              <hr />
              <h5 className='f-bold text-theme'>Contact Details</h5>
              <div className='info-block'>
                <label>Mobile</label>
                <p>+91 9494 318194</p>
              </div>
              <div className='info-block'>
                <label>Email Address</label>
                <p>shalem@vsrassociates.in</p>
              </div>
              <div className='info-block'>
                <label>Address</label>
                <p>#792, 2nd Floor, Sree Sarada Nilayam, Mpch Society, Jayaprakash Nagar, Srinagar Colony, Hyderabad - 500073</p>
              </div>
            </div>
            <div className='col-12 p-2'>
              <hr />
              <h5 className='f-bold text-theme'>We Offer</h5>
              <ul>
                <li>Startup Registration</li>
                <li>GST Registration & Filling</li>
                <li>Income Tax Filling</li>
                <li>Compliance</li>
                <li>Audit & Compliance</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reach;
