import React from 'react';
import { Helmet } from 'react-helmet';

const Services = () => {
  return (
    <div className="container-fluid">
      <Helmet>
        <title>Services | VSR & Associates LLP</title>
        <meta name="description" content="Our services at VSR & Associates LLP" />
      </Helmet>
      <div className='row'>
        <div className='col p-0'>
          <div className='page-header'>
            <h2 className='f-bold'>Our Services</h2>
          </div>
          <div className='page-content p-5'>
            <p>From strategic Advisory and insightful Consulting to robust Assurance services...</p>
          </div>
          </div>
        </div>
      {/* Add more content as needed */}
    </div>
  );
};

export default Services;
