import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import './Home.css';

const Home = () => {
  return (
    <div className="container-fluid">
      <Helmet>
        <title>Home | VSR & Associates LLP</title>
        <meta name="description" content="VSR & Associates LLP - Home page" />
      </Helmet>
      <div className='row'>
        <div className='col p-0'>
          <div className='home-banner'>
            <div className='row'>
              <div className='col p-0'>
                <div className='home-banner-content'>
                  <h2 className='f-bold'>Welcome to VSR & Associates LLP</h2>
                  <p>Beyond financial analysis, we prioritize relationships, communication, and ethical standards. We are committed to fostering trust with our clients through transparent practices, reliable insights, and an unwavering dedication to integrity, ensuring confidence in every aspect of our service.</p>
                </div>
              </div>
              {
                window.innerWidth>500 && <div className='col p-0'>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className='row justify-content-center'>
        <div className='col-12 p-5 text-center'>
          <h2 className='f-bold'>About Us</h2>
        </div>
        {
          window.innerWidth>500 && <div className='col-1'></div>
        }
        {
          window.innerWidth>500 && <div className='col-5'>
            <img alt='Finance & Account' style={{borderRadius: '4px'}} className='img-fluid' src='https://plus.unsplash.com/premium_photo-1679923906308-c26a0e2ca510?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' />
          </div>
        }
        <div className={window.innerWidth>500?'col-5':'col-11'}>
          <p>Established as an entity in the realms of Advisory, Consulting, and Assurance, VSR & Associates LLP proudly carries a legacy forged through years of extensive experience and exposure. Our expertise, honed over time, is deeply rooted in a wealth of knowledge and is consistently acknowledged for quality.</p>
          <p>Collaborating with pivotal industry stakeholders, we go beyond traditional roles, introducing innovative skills and capabilities that empower organizations to thrive in fiercely competitive environments. Our forward-looking vision is committed to driving a future characterized by sustainability, inclusivity, groundbreaking innovation, and a lasting positive impact on all facets of our interconnected world.</p>
          <p>Beyond financial analysis, we prioritize relationships, communication, and ethical standards. We are committed to fostering trust with our clients through transparent practices, reliable insights, and an unwavering dedication to integrity, ensuring confidence in every aspect of our service.</p>
        </div>
      </div>
      <div className='row justify-content-center' style={{background: '#efefef'}}>
        <div className={window.innerWidth>500?'col-8 p-5 text-center':'col-12 p-5 text-center'}>
          <h2 className='f-bold'>Our Services</h2>
          <p>We understand that for any organization to be successful service is the key factor and hence we always strive hard to provide our clients the best possible services at each and every stage of their business.</p>
        </div>
        <div className={window.innerWidth>500?'col-9 p-0':'col-12'}>
          <div className='row m-0'>
            <div className='col-4 p-0'>
              <div className='service-card'>
                <h4>Financial Planning</h4>
                <p>Tailored financial planning services designed to help individuals and businesses achieve their financial goals through strategic advice and actionable plans.</p>
              </div>
            </div>
            <div className='col-4 p-0'>
              <div className='service-card'>
                <h4>Book Keeping &amp; Outsourcing</h4>
                <p>Comprehensive bookkeeping and outsourcing solutions to manage your financial records accurately and efficiently, allowing you to focus on your core business activities.</p>
              </div>
            </div>
            <div className='col-4 p-0'>
              <div className='service-card'>
                <h4>GST Registration &amp; Filing</h4>
                <p>Hassle-free GST registration and filing services ensuring compliance with tax regulations, timely submissions, and minimizing liabilities.</p>
              </div>
            </div>
            <div className='col-4 p-0'>
              <div className='service-card'>
                <h4>Company Formation &amp; Registration</h4>
                <p>Expert assistance in the formation and registration of companies, guiding you through the legal requirements and ensuring a smooth start to your business operations.</p>
              </div>
            </div>
            <div className='col-4 p-0'>
              <div className='service-card'>
                <h4>Tax Filing Expert</h4>
                <p>Professional tax filing services to ensure accurate and timely submissions, maximizing deductions, and minimizing tax liabilities.</p>
              </div>
            </div>
            <div className='col-4 p-0'>
              <div className='service-card'>
                <h4>Statutory &amp; Voluntary Audits</h4>
                <p>Thorough statutory and voluntary audit services to ensure compliance with regulatory requirements and provide valuable insights into your financial health.</p>
              </div>
            </div>
            {/* <div className='col-4 p-0'>
              <div className='service-card'>
                <h4>Company Annual Filings</h4>
                <p>Reliable annual filing services to keep your company compliant with statutory requirements, ensuring timely and accurate submissions of annual returns and financial statements.</p>
              </div>
            </div> */}
            <div className='col-12 p-4 text-center' style={{marginBottom: '20px'}}>
              <Link to='/services' className='btn btn-sm btn-primary'>View All Services</Link>
            </div>
          </div>
        </div>
      </div>
      <div className='row justify-content-center'>
        <div className={window.innerWidth>500?'col-8 p-5 text-center':'col-12 p-5 text-center'}>
          <h2 className='f-bold'>Testimonials</h2>
          <p>Read what our satisfied clients have to say about our exceptional services and how we've helped them achieve their financial and business goals.</p>
        </div>
        <div className='col-9' style={{paddingBottom: '5vh'}}>
          <div className='row m-0 justify-content-evenly'>
            <div className='col-5' style={{background: '#efefef', padding: '10px 10px 5px', borderRadius: '4px', fontWeight: '500', fontStyle: 'italic'}}>
              <blockquote>"VSR & Associates LLP provided exceptional service and valuable insights that helped us streamline our financial operations." - Client A</blockquote>
            </div>
            <div className='col-5' style={{background: '#efefef', padding: '10px 10px 5px', borderRadius: '4px', fontWeight: '500', fontStyle: 'italic'}}>
              <blockquote>"Their expertise in tax advisory saved us significant costs and ensured compliance with all regulations." - Client B</blockquote>
            </div>
          </div>
        </div>
      </div>
      <div className='row justify-content-center' style={{background: '#efefef'}}>
        <div className={window.innerWidth>500?'col-8 p-5 text-center':'col-12 p-5 text-center'}>
          <h2 className='f-bold'>Talk to Our Tax Expert</h2>
          <p>Have questions about your taxes? Get in touch with our expert team for personalized advice and solutions.</p>
          <Link to='/contact' className='btn btn-sm btn-primary'>Contact Us</Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
