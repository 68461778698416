import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Header = () => {
  return (
    <header style={{borderBottom: '1px solid #ddd'}}>
      <div className={window.innerWidth>500?'container p-3':'container-fluid p-3'}>
        <div className="d-flex justify-content-between align-items-center">
          <Link to="/" className={`text-theme ${window.innerWidth>500?'mx-2':''}`} style={{textDecoration: 'none'}}>
            <h1 className="h3 text-theme" style={{fontWeight: 'bold'}}>VSR & Associates LLP</h1>
          </Link>
          {
            window.innerWidth>500?<nav>
              <Link to="/" className="text-black mx-2">HOME</Link>
              <Link to="/about" className="text-black mx-2">ABOUT</Link>
              <Link to="/services" className="text-black mx-2">SERVICES</Link>
              <Link to="/contact" className="text-black mx-2">CONTACT</Link>
            </nav>:<></>
          }
        </div>
      </div>
    </header>
  );
};

export default Header;
