import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Reach from './components/Reach';
import Contact from './components/Contact';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col p-0">
          <Router>
            { window.location.href.indexOf('reach') === -1 && <Header />}
            <Switch>
              <Route path="/reach" component={Reach} />
              <Route path="/" exact component={Home} />
              <Route path="/about" component={About} />
              <Route path="/services" component={Services} />
              <Route path="/contact" component={Contact} />
            </Switch>
            { window.location.href.indexOf('reach') === -1 && <Footer />}
          </Router>
        </div>
      </div>
    </div>
  );
}

export default App;
