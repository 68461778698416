import React from 'react';
import { Helmet } from 'react-helmet';

const About = () => {
  return (
    <div className="container-fluid">
      <Helmet>
        <title>About Us | VSR & Associates LLP</title>
        <meta name="description" content="Learn more about VSR & Associates LLP, our journey, values, and commitment to client satisfaction." />
      </Helmet>
      <div className='row'>
        <div className='col p-0'>
          <div className='page-header'>
            <h2 className='f-bold'>About Us</h2>
          </div>
          <div className='page-content p-5'>
            <p>Working towards Client Satisfaction!</p>
            <p>Welcome to VSR & Associates LLP, a firm of Chartered Accountants with roots tracing back to 2024. Based in Hyderabad, our journey began with a commitment to excellence, a legacy now enriched by decades of invaluable experience and extensive industry exposure.</p>
            <p>Our expansion beyond regional confines is marked by branch offices strategically positioned across the nation, symbolizing our dedication to serving clients with a broad and diverse perspective.</p>
            <p>On the global stage, we carry the torch of our profession, leveraging our presence to infuse new ideas and practices into a competitive market.</p>
            <p>What sets us apart is not just our extensive reach but the fusion of tradition with contemporary tools and expertise. This synergy results in value-added services that go beyond conventional practices, providing tangible benefits to our clients’ businesses. At the heart of our identity lies an unwavering commitment to the core values of our profession.</p>
            <p>With a reservoir of experience and industry accolades, we’ve earned a reputation for delivering quality that speaks volumes. Embracing technological advancements, our methodologies are not just adaptive but tailored to meet the unique needs of our clients.</p>
            <p>Our client interactions are a harmonious blend of professionalism and personal rapport, striking the perfect balance between the two which empowers us to extract bespoke solutions for businesses across diverse industry sectors.</p>
            <p>Our commitment doesn’t end with delivery; we go further by being a partner in getting end results to the client’s satisfaction.</p>
            <h3>Core Values</h3>
            <h4>Open Communication & Transparency</h4>
            <p>At the core of the firm is open communication and transparency, ensuring a free flow of information among team members and clients, establishing trust and reinforcing the commitment to ethical and accountable practices.</p>
            <h4>Ethical Principles</h4>
            <p>Ethical principles form the bedrock, guiding our professionals in maintaining integrity, objectivity, and independence. Upholding these principles safeguards the reliability and credibility of our deliverables, crucial for fostering public and investor trust.</p>
            <h4>Client-Centric Approach</h4>
            <p>Tailoring services to meet client needs, understanding the business intricacies, and providing value-added insights and thereby not only strengthening client relationships but also enhancing the overall quality and relevance of our services.</p>
          </div>
        </div>
        </div>
      </div>
  );
};

export default About;
