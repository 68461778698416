import React from 'react';
import { Helmet } from 'react-helmet';
import './Contact.css';

const Contact = () => {
  return (
    <div className="container-fluid">
      <Helmet>
        <title>Contact Us | VSR & Associates LLP</title>
        <meta name="description" content="Get in touch with VSR & Associates LLP" />
      </Helmet>
      <div className='row' style={{background: '#efefef'}}>
        <div className='col p-0'>
          <div className='page-header'>
            <h2 className='f-bold'>Contact Us</h2>
          </div>
          <div className='page-content p-5'>
            <div className='row m-0 justify-content-between'>
              <div className='col-5 p-0'>
                <h5 className='f-bold text-theme'>Contact Details</h5>
                <div className='info-block'>
                  <label>Mobile</label>
                  <p>+91 9494 318194</p>
                </div>
                <div className='info-block'>
                  <label>Email Address</label>
                  <p>shalem@vsrassociates.in</p>
                </div>
                <div className='info-block'>
                  <label>Address</label>
                  <p>#792, 2nd Floor, Sree Sarada Nilayam, Mpch Society, Jayaprakash Nagar, Srinagar Colony, Hyderabad - 500073</p>
                </div>
                <div className='info-block'>
                  <label>Location &amp; Maps</label>
                </div>
              </div>
              <div className='col-5 p-0'>
                <div className='contact-form'>
                  <form>
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">Full Name</label>
                      <input type="text" className="form-control" placeholder='Full Name' id="name" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">Email</label>
                      <input type="email" className="form-control" placeholder='Your Email Address' id="email" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="phone" className="form-label">Phone Number</label>
                      <input type="tel" className="form-control" id="phone" placeholder='Your Mobile Number' />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="message" className="form-label">Your Message</label>
                      <textarea className="form-control" id="message" placeholder='Enter your message....' rows="3"></textarea>
                    </div>
                    <button type="submit" className="btn btn-primary">Submit</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Email: reach@vsrassociates.in
      Phone: +91 94943 18194 */}
    </div>
  );
};

export default Contact;
